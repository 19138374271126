import { Fragment } from "react"

const TermsConditions = (props) => {

    let contentSize = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "is-normal" : "is-medium"

    return (
        <div style={{ position: "fixed" }} className={`modal is-clipped ${props.className}`}>
            <div className="modal-background"></div>
            <div className="modal-card p-1">
                <header className="modal-card-head p-3">
                    <div className="modal-card-title is-size-3-touch is-size-2-desktop">
                        {props.tc.document.title}
                    </div>
                    <button className="delete" onClick={() => props.closeModal()}></button>
                </header>
                <section className="modal-card-body px-3 py-2">
                    <span className="tag is-info is-flex is-justify-content-center mb-3 is-size-5-touch is-size-4-desktop">
                        {`actualizado ${props.tc.active_from}`}
                    </span>
                    <div className={`content ${contentSize} mb-3`}>
                        {
                            props.tc.document.content_type === 'ordered-list-number'
                                ?
                                (
                                    <ol type="1">
                                        {
                                            props.tc.document.content.map((obj1, idx1) => {
                                                return (
                                                    <Fragment key={idx1}>
                                                        <li key={`list-item-${idx1}`} className="is-size-3-touch is-size-1-desktop has-text-weight-bold mb-3">{obj1.text}</li>
                                                        {
                                                            obj1.content_type === 'paragraphs'
                                                                ?
                                                                obj1.content.map((obj2, idx2) => {
                                                                    return (
                                                                        <p key={`prg-${idx1}-${idx2}`}>{obj2.text}</p>
                                                                    )
                                                                })
                                                                :
                                                                obj1.content_type === 'ordered-list-letter'
                                                                    ?
                                                                    (
                                                                        <ol key={`list-${idx1}`} className="is-lower-alpha">
                                                                            {
                                                                                obj1.content.map((obj2, idx2) => {
                                                                                    return (
                                                                                        <Fragment key={`container-${idx1}-${idx2}`}>
                                                                                            <li key={`list-item-${idx1}-${idx2}`} className="mb-2 has-text-weight-bold">{obj2.text}</li>
                                                                                            {
                                                                                                obj2.content_type === 'paragraphs'
                                                                                                    ?
                                                                                                    obj2.content.map((obj3, idx3) => {
                                                                                                        return (
                                                                                                            <p key={`prg-${idx1}-${idx2}-${idx3}`}>{obj3.text}</p>
                                                                                                        )
                                                                                                    })
                                                                                                    :
                                                                                                    ""
                                                                                            }
                                                                                        </Fragment>

                                                                                    )
                                                                                })
                                                                            }
                                                                        </ol>
                                                                    )
                                                                    :
                                                                    obj1.content_type === 'combined'
                                                                        ?
                                                                        obj1.content.map((obj2, idx2) => {
                                                                            return (
                                                                                <Fragment key={`container-${idx1}-${idx2}`}>
                                                                                    {
                                                                                        obj2.content_type === 'paragraphs'
                                                                                            ?
                                                                                            obj2.content.map((obj3, idx3) => {
                                                                                                return (
                                                                                                    <p key={`prg-${idx1}-${idx2}-${idx3}`}>{obj3.text}</p>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            obj2.content_type === 'unordered-list'
                                                                                                ?
                                                                                                    <ul>
                                                                                                        {
                                                                                                            obj2.content.map((obj3, idx3) => {
                                                                                                                return (
                                                                                                                    <li key={`list-item-${idx1}-${idx2}-${idx3}`}>{obj3.text}</li>
                                                                                                                )
                                                                                                            })
                                                                                                        }

                                                                                                    </ul>

                                                                                                :
                                                                                                ""
                                                                                    }
                                                                                </Fragment>
                                                                            )

                                                                        })
                                                                        :
                                                                        ""
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </ol>
                                )
                                :
                                ""

                        }

                    </div>
                </section>
                <footer className="modal-card-foot p-3 is-justify-content-center">
                    <button className="button is-size-7-touch is-size-6-desktop" onClick={() => props.closeModal()}>
                        Cerrar
                    </button>
                </footer>
            </div >
        </div >
    );
};

export default TermsConditions;