import React, { useState, useEffect } from 'react';

const AuthContext = React.createContext({
    user: {},
    expired: false,
    isLoggedIn: false,
    onLogin: () => { },
    checkExpiration: () => { },
});

export const AuthContextProvider = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [user, setUser] = useState({})
    const [expired, setExpired] = useState(false)

    useEffect(() => {

    }, [])

    const loginHandler = (user) => {
        setUser({ ...user })
        if (Object.keys(user).length > 0) {
            setIsLoggedIn(true)
        } else {
            setIsLoggedIn(false)
        }
    }

    // eslint-disable-next-line no-unused-vars
    const expirationHandler = (text) => {
        if (Object.keys(user).length > 0) {
            const now = new Date();
            const exp = new Date(user.token_expiration * 1000)

            // console.log(`${text} exp.toString: ${exp.toLocaleString()}`)
            // console.log(`${text} now.toString: ${now.toLocaleString()}`)
            

            const now_utc_timestamp = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
            const exp_utc_timestamp = Date.UTC(exp.getFullYear(), exp.getMonth(), exp.getDate(), exp.getHours(), exp.getMinutes(), exp.getSeconds(), exp.getMilliseconds());

            if ((Math.floor(exp_utc_timestamp / 1000) - 10*60)  < Math.floor(now_utc_timestamp / 1000)) {
                setExpired(true)
            }
        }
    }

    return (
        <AuthContext.Provider
            value={{
                user: user,
                expired: expired,
                isLoggedIn: isLoggedIn,
                onLogin: loginHandler,
                checkExpiration: expirationHandler,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext;