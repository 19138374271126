import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// https://v5.reactrouter.com/web/guides/scroll-restoration

const RestoreScrollToTop = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return null;

}

export default RestoreScrollToTop;