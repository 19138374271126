import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../store/auth-context";

const NavBar = (props) => {
  const ctx = useContext(AuthContext);

  const [expanded, setExpanded] = useState(false);

  const clickMenuHandler = () => {
    if (ctx.isLoggedIn) {
      setExpanded((prevExpanded) => !prevExpanded);
    }
  };

  const clickOptionHandler = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <header>
      <nav className="navbar is-fixed-top is-primary">
        <div className="navbar-brand">
          <div className="navbar-item">
            {ctx.isLoggedIn && <Link
              className="title is-size-3 has-text-white has-text-weight-semibold"
              to="/"
            >
              PMATCH
            </Link>
            }
            {!ctx.isLoggedIn && <h4
              className="title is-size-3 has-text-white has-text-weight-semibold"
            >
              PMATCH
            </h4>
            }
          </div>

          <div
          style={{marginLeft: "auto" }}
            className={`navbar-burger ${expanded ? "is-active" : ""}`}
            onClick={clickMenuHandler}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={`navbar-menu ${expanded ? "is-active" : ""}`}>
          <div className="navbar-start">
            {ctx.isLoggedIn &&
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link">Productos</div>
                <div className="navbar-dropdown">

                  <Link
                    to="/categories"
                    className="navbar-item"
                    onClick={clickOptionHandler}
                  >
                    Comparador por Producto
                  </Link>

                  <Link
                    to="/carts"
                    className="navbar-item"
                    onClick={clickOptionHandler}
                  >
                    Canastas de Comparación
                  </Link>

                  <Link
                    to="/alerts"
                    className="navbar-item"
                    onClick={clickOptionHandler}
                  >
                    Alertas
                  </Link>

                </div>
              </div>
            }
            {ctx.isLoggedIn &&
              <Link
                to="/account"
                className="navbar-item"
                onClick={clickOptionHandler}
              >
                Mi Cuenta
              </Link>
            }
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                {ctx.isLoggedIn &&
                  (
                    <a href={`${props.uri}/mapi/logout`} className={`button is-primary ${window.innerWidth < 769 ? "is-outlined" : "is-inverted"}`}>
                      Cerrar Sesion
                    </a>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
