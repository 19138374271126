import { useContext } from "react";
import { useLocation } from 'react-router-dom';
import AuthContext from "../../store/auth-context";

const CheckSession = props => {

    const ctx = useContext(AuthContext)
    const location = useLocation()
    const now = new Date().getTime()
    const exp = new Date(ctx.user.token_expiration * 1000).getTime()

    return (
        <div style={{ position: "fixed" }} className={`modal is-clipped is-active`}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <article className="message">
                    <div className="message-header">
                        <p className="is-size-4-touch is-size-3-desktop">ALERTA DE SESION</p>
                    </div>
                    {
                        exp > now && ctx.user.email !== "demo@pmatch.cl" ?
                            <div className="message-body has-text-centered">
                                <p className="title is-size-5-touch is-size-4-desktop">Su sesion pronto expirará, utilice una opción.</p>
                                <div className="is-flex is-justify-content-space-around is-align-items-center">
                                    <a href={`${props.uri}/mapi/logout`} className="button is-primary is-size-6-touch is-size-5-desktop">
                                        Cerrar Sesion
                                    </a>
                                    <a href={`${props.uri}/mapi/access/refresh?loc=${location.pathname}`} className="button is-primary is-size-6-touch is-size-5-desktop">
                                        Renovar Sesion
                                    </a>
                                </div>
                            </div>
                            :
                            <div className="message-body has-text-centered">
                                <p className="title is-size-5-touch is-size-4-desktop">Su sesion ha expirado.</p>
                                <div className="is-flex is-justify-content-space-around is-align-items-center">
                                    <a href={`${props.uri}/mapi/logout`} className="button is-primary is-size-6-touch is-size-5-desktop">
                                        Volver a Iniciar Sesion
                                    </a>
                                </div>
                            </div>
                    }

                </article>
            </div>
        </div>
    );
}

export default CheckSession;