const API_DOMAIN = 'https://api.pmatch.cl'; // UNCOMMENT IT FOR PRODUCTION
const API_BASE = '/v4.3' // UNCOMMENT IT FOR PRODUCTION
//const API_DOMAIN = ''; // DEVELOPMENT
//const API_BASE = '/v4.3' // DEVELOPMENT
const API_SYSTEM = '/system/';
const API_CATEGORIES = '/categories/';
const API_CARTS = '/carts/';
const API_ALERTS = '/alerts/';
const API_PAYMENTS = '/payments/';
const API_PRODUCTS = '/products';
const API_FILTERS = '/filters';
const API_MATCH = '/match';
const API_IMAGE = '/image';


// --------------------------------- REFACTORING PMATCH

export async function getActiveCategories(accessToken) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_CATEGORIES}`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
  });
  const data = await response.json();
  
  if (response.status !== 200) {
    if (response.status === 401) {
      throw new Error('401 | El TOKEN expiro, por favor vuelva a logearse')
    } else if (response.status === 404) {
      throw new Error('404 | No se encontraron categorias')
    } else if (response.status === 500) {
      throw new Error('500 | Error en el Servidor, lo sentimos, vuelva a intentarlo')
    } else {
      throw new Error("XXX | "+data.message || 'Could not fetch filters.');
    }
  }

  // Add code to transform response if needed
  // ----------------------------------------
  return data;
}

export async function getActiveProductsByCategory(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_CATEGORIES}${requestData.categoryId}${API_PRODUCTS}`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  
  const data = await response.json();
  
  if (response.status !== 200){
    if (response.status === 400) {
      throw new Error('400 | La URL fue modificada manualmente')
    }
    else if (response.status === 404) {
      throw new Error('404 | No se encontraron productos para la categoria')
    } else if (response.status === 500) {
      throw new Error('500 | Error en el Servidor, lo sentimos, vuelva a intentarlo')
    }else {
      throw new Error(data.message || 'Could not fetch products by category.');
    }
  }
  
  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getActiveFiltersByCategory(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_CATEGORIES}${requestData.categoryId}${API_FILTERS}`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  
  const data = await response.json();
  
  if (response.status !== 200){
    if (response.status === 400) {
      throw new Error('400 | La URL fue modificada manualmente')
    }
    else if (response.status === 404) {
      throw new Error('404 | No se encontraron filtros para la categoria')
    } else if (response.status === 500) {
      throw new Error('500 | Error en el Servidor, lo sentimos, vuelva a intentarlo')
    }else {
      throw new Error(data.message || 'Could not fetch products by category.');
    }
  }
  
  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getProductMatchPrices(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_CATEGORIES}${requestData.categoryId}${API_PRODUCTS}/${requestData.productId}${API_MATCH}`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  const data = await response.json();

  if (response.status !== 200){
    if (response.status === 400) {
      throw new Error('400 | La URL fue modificada manualmente')
    } else if (response.status === 404) {
      throw new Error('404 | La URL no fue encontrada')
    } else if (response.status === 500) {
      throw new Error('500 | Error en el Servidor, lo sentimos, vuelva a intentarlo')
    } else {
      throw new Error(response.status + ' | ' + data.message);
    }
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getProductMatchImage(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_CATEGORIES}${requestData.categoryId}${API_PRODUCTS}/${requestData.productId}${API_IMAGE}`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  const data = await response.json();

  if (response.status !== 200){
    if (response.status === 400) {
      throw new Error('400 | La URL fue modificada manualmente')
    } else if (response.status === 404) {
      throw new Error('404 | La URL no fue encontrada')
    } else if (response.status === 500) {
      throw new Error('500 | Error en el Servidor, lo sentimos, vuelva a intentarlo')
    } else {
      throw new Error(response.status + ' | ' + data.message);
    }
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getProductMatchDetails(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_CATEGORIES}${requestData.categoryId}${API_PRODUCTS}/${requestData.productId}`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  const data = await response.json();

  if (response.status !== 200){
    if (response.status === 400) {
      throw new Error('400 | La URL fue modificada manualmente')
    } else if (response.status === 404) {
      throw new Error('404 | La URL no fue encontrada')
    } else if (response.status === 500) {
      throw new Error('500 | Error en el Servidor, lo sentimos, vuelva a intentarlo')
    } else {
      throw new Error(response.status + ' | ' + data.message);
    }
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getUserAlertsWithStatus(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_ALERTS}${requestData.email}`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  const data = await response.json();

  if (response.status !== 200){
    if (response.status === 400) {
      throw new Error('400 | La URL fue modificada manualmente')
    } else if (response.status === 404) {
      throw new Error('404 | La URL no fue encontrada')
    } else if (response.status === 500) {
      throw new Error('500 | Error en el Servidor, lo sentimos, vuelva a intentarlo')
    } else {
      throw new Error(response.status + ' | ' + data.message);
    }
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getAlertRules(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_ALERTS}rules`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch cart price.');
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getUserAlertWithStatus(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_ALERTS}${requestData.email}/${requestData.aProductId}`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  const data = await response.json();

  if (response.status !== 200){
    if (response.status === 400) {
      throw new Error('400 | La URL fue modificada manualmente')
    } else if (response.status === 404) {
      throw new Error('404 | La URL no fue encontrada')
    } else if (response.status === 500) {
      throw new Error('500 | Error en el Servidor, lo sentimos, vuelva a intentarlo')
    } else {
      throw new Error(response.status + ' | ' + data.message);
    }
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function createUserAlert(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_ALERTS}${requestData.email}`, {
    method: "POST",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}`, 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      aProductId: requestData.aProductId,
      aRuleId: requestData.aRuleId,
      aRuleHasParam: requestData.aRuleHasParam,
      aRuleParamType: requestData.aRuleParamType,
      aRuleParamValue: requestData.aRuleParamValue,
    })
  });
  const data = await response.json();

  if (!response.ok) {
    if (response.status === 403) {
      throw new Error('403 | Limite CUENTA: máximo de ' + data.limit + ' alerta(s)')
    } else if (response.status === 500) {
      throw new Error('500 | Error en el Servidor, lo sentimos, vuelva a intentarlo')
    } else {
      throw new Error("XXX | " + data.message || 'Could not create the alert.');
    }
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function deleteUserAlert(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_ALERTS}${requestData.email}`, {
    method: "DELETE",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}`, 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      aProductId: requestData.aProductId,
    })
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(response.status + " | " + data.message || 'Could not delete user alert');
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function updateUserAlert(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_ALERTS}${requestData.email}`, {
    method: "PATCH",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}`, 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      aProductId: requestData.aProductId,
      aRuleId: requestData.aRuleId,
      aRuleHasParam: requestData.aRuleHasParam,
      aRuleParamType: requestData.aRuleParamType,
      aRuleParamValue: requestData.aRuleParamValue,
    })
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(response.status + " | " + data.message || 'Could not check user alerts');
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function readUserCarts(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_CARTS}${requestData.email}`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  const data = await response.json();
  
  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch filters.');
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function updateUserCart(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_CARTS}${requestData.email}`, {
    method: "PATCH",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}`, 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      cartCode: requestData.cartCode,
      codes: requestData.codes.join(),
      quantities: requestData.quantities.join(),
    })
  });
  const data = await response.json();
  
  if (!response.ok) {
    throw new Error(response.status + " | " + data.message);
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function createUserCart(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_CARTS}${requestData.email}`, {
    method: "POST",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}`, 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      name: requestData.name,
      codes: requestData.codes.join(),
      quantities: requestData.quantities.join(),
    })
  });
  const data = await response.json();

  if (!response.ok) {
    if (response.status === 403) {
      throw new Error('403 | Limite CUENTA: máximo de ' + data.limit + ' canasta(s)')
    } else if (response.status === 500) {
      throw new Error('500 | Error en el Servidor, lo sentimos, vuelva a intentarlo')
    } else {
      throw new Error("XXX | " + data.message || 'Could not fetch carts.');
    }
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function calculateCartPrice(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_CARTS}${requestData.cartId}/price?codes=${requestData.codes.join()}&quantities=${requestData.quantities.join()}&markets=${requestData.markets.join()}`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(response.status + " | " + data.message);
  }

  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getSystemStatus(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_SYSTEM}status`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(response.status + " | " + data.message || 'Could not check user alerts');
  }
  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getTermsConditions() {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_SYSTEM}terms-conditions`, {
    method: "GET",
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(response.status + " | " + data.message || 'Could not check user alerts');
  }
  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getPrivacyPolicy() {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_SYSTEM}privacy-policy`, {
    method: "GET",
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(response.status + " | " + data.message || 'Could not check user alerts');
  }
  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

export async function getPaymentPlans(requestData) {
  const response = await fetch(`${API_DOMAIN}${API_BASE}${API_PAYMENTS}plans`, {
    method: "GET",
    headers: new Headers({ Authorization: `Bearer ${requestData.accessToken}` }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(response.status + " | " + data.message || 'Could not check user alerts');
  }
  // Add code to transform response if needed
  
  // ----------------------------------------
  return data;
}

// --------------------------------- END - REFACTORING PMATCH



