import { Fragment, useState, useContext, useEffect, lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthContext from "./store/auth-context";

import NavBar from "./components/NavBar/NavBar";
import Loader from "./components/UI/Loader";
import NotFound from "./components/UI/NotFound";
import NotAuthenticated from "./components/UI/NotAuthenticated";
import AuthView from "./views/AuthView";

import CheckSession from "./components/Authentication/CheckSession";
import RestoreScrollToTop from "./components/UI/RestoreScrollToTop";

const Home = lazy(() => import("./views/Home"));
const CategoriesView = lazy(() => import("./views/CategoriesView"));
const ProductsView = lazy(() => import("./views/ProductsView"));
const AlertsView = lazy(() => import("./views/AlertsView"));
const CartsView = lazy(() => import("./views/CartsView"));
const AccountView = lazy(() => import("./views/AccountView"));
const PlansView = lazy(() => import("./views/PlansView"));

import useWindowSize from "./components/UI/ScreenSize";


const authURI = 'https://auth.pmatch.cl';
//const authURI = 'http://192.168.1.102:9000'

function App() {
  const ctx = useContext(AuthContext);

  const [firstRender, setFirstRender] = useState(true)
  const [sessionExpired, setSessionExpired] = useState(false)
  const [apiSystemStatus, setApiSystemStatus] = useState({})
  const [newApiCategories, setNewApiCategories] = useState({})
  const [newApiProducts, setNewApiProducts] = useState({})
  const [newApiFilters, setNewApiFilters] = useState({})
  const [newApiMatchPrices, setNewApiMatchPrices] = useState({})
  const [newApiUserAlerts, setNewApiUserAlerts] = useState({})
  const [newApiAlertRules, setNewApiAlertRules] = useState([])
  const [newApiCarts, setNewApiCarts] = useState([])
  const [newApiCartsPrices, setNewApiCartsPrices] = useState({})
  const [cartsReachLimit, setCartsReachLimit] = useState({})
  const [newApiPlans, setNewApiPlans] = useState([])
  const [newApiTermsConditions, setNewApiTermsConditions] = useState({})
  const [newApiPrivacyPolicy, setNewApiPrivacyPolicy] = useState({})

  const windowSize = useWindowSize()

  useEffect(() => {
    ctx.checkExpiration("App.js");
    if ((!ctx.isLoggedIn && Object.keys(ctx.user).length === 0 && firstRender) || ctx.expired && !sessionExpired) {
      fetch(`${authURI}/mapi/user`, {
        credentials: 'include',
      })
        .then(response => response.json())
        .then(data => {
          if (Object.keys(data).length > 0) {
            ctx.onLogin({ ...data })
          }
          setFirstRender(false)
          if (ctx.expired) {
            setSessionExpired(true)
          }
        })
    }

  }, [ctx, firstRender])



  return (
    <Fragment>
      <RestoreScrollToTop />
      <NavBar uri={authURI} />
      <Suspense
        fallback={
          <section className="section p-3">
            <Loader />
          </section>
        }
      >
        <Switch>
          <Route path="/" exact>
            {ctx.isLoggedIn && !ctx.expired && <Redirect to="/home" />}
            {!ctx.isLoggedIn && !firstRender && <Redirect to="/auth" />}
            {!ctx.isLoggedIn && firstRender && <section className="section p-3"><Loader /></section>}
            {ctx.expired && <CheckSession uri={authURI} />}
          </Route>
          <Route path="/auth" exact>
            <AuthView
              uri={authURI}
              apiTermsConditions={newApiTermsConditions}
              setApiTermsConditions={setNewApiTermsConditions}
              apiPrivacyPolicy={newApiPrivacyPolicy}
              setApiPrivacyPolicy={setNewApiPrivacyPolicy}
              windowSize={windowSize}
            />
          </Route>
          <Route path="/home" exact>
            {ctx.isLoggedIn && !ctx.expired && <Home
              uri={authURI}
              apiSystemStatus={apiSystemStatus}
              setApiSystemStatus={setApiSystemStatus}
              alerts={newApiUserAlerts}
              setAlerts={setNewApiUserAlerts}
              windowSize={windowSize}
              products={newApiProducts}
              apiTermsConditions={newApiTermsConditions}
              setApiTermsConditions={setNewApiTermsConditions}
              apiPrivacyPolicy={newApiPrivacyPolicy}
              setApiPrivacyPolicy={setNewApiPrivacyPolicy}
            />}
            {!ctx.isLoggedIn && !firstRender && <section className="section p-3"><NotAuthenticated /></section>}
            {!ctx.isLoggedIn && firstRender && <section className="section p-3"><Loader /></section>}
            {ctx.expired && <CheckSession uri={authURI} />}
          </Route>
          <Route path="/categories" exact>
            <section className="section p-3">
              {ctx.isLoggedIn && !ctx.expired && <CategoriesView
                accessToken={ctx.user.access_token}
                categories={newApiCategories}
                setCategories={setNewApiCategories}
                carts={newApiCarts}
                setCarts={setNewApiCarts}
                uri={authURI}
                windowSize={windowSize}
              />}
              {!ctx.isLoggedIn && !firstRender && <section className="section p-3"><NotAuthenticated /></section>}
              {!ctx.isLoggedIn && firstRender && <section className="section p-3"><Loader /></section>}
              {ctx.expired && <CheckSession uri={authURI} />}
            </section>
          </Route>
          <Route path="/categories/:categoryId" exact>
            <section className="section p-3">
              {ctx.isLoggedIn && !ctx.expired &&
                <ProductsView
                  accessToken={ctx.user.access_token}
                  products={newApiProducts}
                  setProducts={setNewApiProducts}
                  filters={newApiFilters}
                  setFilters={setNewApiFilters}
                  categories={newApiCategories}
                  setCategories={setNewApiCategories}
                  matchPrices={newApiMatchPrices}
                  setMatchPrices={setNewApiMatchPrices}
                  alerts={newApiUserAlerts}
                  setAlerts={setNewApiUserAlerts}
                  alertRules={newApiAlertRules}
                  setAlertRules={setNewApiAlertRules}
                  carts={newApiCarts}
                  setCarts={setNewApiCarts}
                  setCartsPrices={setNewApiCartsPrices}
                  cartsReachLimit={cartsReachLimit}
                  setCartsReachLimit={setCartsReachLimit}
                  uri={authURI}
                  windowSize={windowSize}
                />}
              {!ctx.isLoggedIn && !firstRender && <section className="section p-3"><NotAuthenticated /></section>}
              {!ctx.isLoggedIn && firstRender && <section className="section p-3"><Loader /></section>}
              {ctx.expired && <CheckSession uri={authURI} />}
            </section>
          </Route>
          <Route path="/categories/:categoryId/:filterId" exact>
            <section className="section p-3">
              {ctx.isLoggedIn && !ctx.expired && <CategoriesView
                accessToken={ctx.user.access_token}
                categories={newApiCategories}
                setCategories={setNewApiCategories}
                carts={newApiCarts}
                setCarts={setNewApiCarts}
                uri={authURI}
                windowSize={windowSize}
              />}
              {!ctx.isLoggedIn && !firstRender && <section className="section p-3"><NotAuthenticated /></section>}
              {!ctx.isLoggedIn && firstRender && <section className="section p-3"><Loader /></section>}
              {ctx.expired && <CheckSession uri={authURI} />}
            </section>
          </Route>
          <Route path="/alerts" exact>
            <section className="section p-3">
              {ctx.isLoggedIn && !ctx.expired &&
                <AlertsView
                  uri={authURI}
                  accessToken={ctx.user.access_token}
                  alertRules={newApiAlertRules}
                  setAlertRules={setNewApiAlertRules}
                  alerts={newApiUserAlerts}
                  setAlerts={setNewApiUserAlerts}
                  products={newApiProducts}
                  windowSize={windowSize}
                />
              }
              {!ctx.isLoggedIn && !firstRender && <section className="section p-3"><NotAuthenticated /></section>}
              {!ctx.isLoggedIn && firstRender && <section className="section p-3"><Loader /></section>}
              {ctx.expired && <CheckSession uri={authURI} />}
            </section>
          </Route>
          <Route path="/carts" exact>
            <section className="section p-3">
              {ctx.isLoggedIn && !ctx.expired && <CartsView
                accessToken={ctx.user.access_token}
                carts={newApiCarts}
                setCarts={setNewApiCarts}
                categories={newApiCategories}
                setCategories={setNewApiCategories}
                products={newApiProducts}
                setProducts={setNewApiProducts}
                filters={newApiFilters}
                setFilters={setNewApiFilters}
                cartsPrices={newApiCartsPrices}
                setCartsPrices={setNewApiCartsPrices}
                cartsReachLimit={cartsReachLimit}
                setCartsReachLimit={setCartsReachLimit}
                uri={authURI}
                windowSize={windowSize}
              />}
              {!ctx.isLoggedIn && !firstRender && <section className="section p-3"><NotAuthenticated /></section>}
              {!ctx.isLoggedIn && firstRender && <section className="section p-3"><Loader /></section>}
              {ctx.expired && <CheckSession uri={authURI} />}
            </section>
          </Route>
          <Route path="/account">
            <section className="section p-3">
              {ctx.isLoggedIn && !ctx.expired && <AccountView
                accessToken={ctx.user.access_token}
                uri={authURI}
                windowSize={windowSize}
                plans={newApiPlans}
                setPlans={setNewApiPlans}
                apiTermsConditions={newApiTermsConditions}
                setApiTermsConditions={setNewApiTermsConditions}
                apiPrivacyPolicy={newApiPrivacyPolicy}
                setApiPrivacyPolicy={setNewApiPrivacyPolicy}
              />
              }
              {!ctx.isLoggedIn && !firstRender && <section className="section p-3"><NotAuthenticated /></section>}
              {!ctx.isLoggedIn && firstRender && <section className="section p-3"><Loader /></section>}
              {ctx.expired && <CheckSession uri={authURI} />}
            </section>
          </Route>
          <Route path="/plans">
            <section className="section p-3">
              {ctx.isLoggedIn && !ctx.expired && <PlansView
                uri={authURI}
                accessToken={ctx.user.access_token}
                windowSize={windowSize}
                plans={newApiPlans}
                setPlans={setNewApiPlans}
              />
              }
              {!ctx.isLoggedIn && !firstRender && <section className="section p-3"><NotAuthenticated /></section>}
              {!ctx.isLoggedIn && firstRender && <section className="section p-3"><Loader /></section>}
              {ctx.expired && <CheckSession uri={authURI} />}
            </section>
          </Route>
          <Route path="*">
            <section className="section p-3">
              <NotFound />
            </section>
          </Route>
        </Switch>
      </Suspense>
    </Fragment>
  );
}

export default App;
