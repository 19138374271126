import { Fragment, useState, lazy, Suspense } from "react";
import Loader from "../UI/Loader";
const RegistrationForm = lazy(() => import("./RegistrationForm"))

const Registration = (props) => {
    const [showForm, setshowForm] = useState(false)

    let elementsSize = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "is-normal" : "is-medium"


    const onClickHandler = () => {
        setshowForm(pShowForm => !pShowForm)
        props.setRegistrationError('');
        props.setVerificationError('');
    }

    return (
        <Fragment>
            {!showForm && (
                <div className="mb-4 has-text-centered">
                    <button onClick={onClickHandler} className={`button is-primary ${elementsSize}`}>Registrarse</button>
                </div>
            )}
            {showForm &&
                <Suspense
                    fallback={
                        <section className="section p-3">
                            <Loader />
                        </section>
                    }
                >
                    <RegistrationForm
                        uri={props.uri}
                        setShowForm={setshowForm}
                        setRegistrationError={props.setRegistrationError}
                        setRegistrationSuccess={props.setRegistrationSuccess}
                        setRegistrationEmail={props.setRegistrationEmail}
                        showInfoRE={props.showInfoRE}
                        showInfoDA={props.showInfoDA}
                        setRegistrationPending={props.setRegistrationPending}
                        toggleTermsConditions={props.toggleTermsConditions}
                        togglePrivacyPolicy={props.togglePrivacyPolicy}
                        windowSize={props.windowSize}
                    />
                </Suspense>}
            {props.registrationError !== '' && (
                <article
                    className={`message is-warning mb-2 ${elementsSize}`}
                >
                    <div className="message-body py-2 has-text-centered">{props.registrationError.split(' | ')[1]}</div>
                </article>
            )}
        </Fragment>);
};

export default Registration;