import { Fragment, useState, useContext, useEffect } from "react";
import AuthContext from "../store/auth-context";

import useHttp from "../hooks/use-http";
import { getTermsConditions, getPrivacyPolicy } from "../lib/api2";

import ValidateEmail from "../components/Authentication/ValidatateEmail";
import Registration from "../components/Authentication/Registration"
import Loader from "../components/UI/Loader";
import DemoAccount from "../components/Authentication/DemoAccount";
import TermsConditions from "../components/Agreements/AV-TermsConditions";
import PrivacyPolicy from "../components/Agreements/AV-PrivacyPolicy";

const AuthView = props => {
    const ctx = useContext(AuthContext);

    const { apiTermsConditions, setApiTermsConditions, apiPrivacyPolicy, setApiPrivacyPolicy } = props

    let elementsSize = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "is-normal" : "is-medium"

    const [registrationEmail, setRegistrationEmail] = useState('');
    const [registrationError, setRegistrationError] = useState('');
    const [registrationPending, setRegistrationPending] = useState(false)
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    const [verificationEmail, setVerificationEmail] = useState('');
    const [verificationError, setVerificationError] = useState('');
    const [verificationPending, setVerificationPending] = useState(false)
    const [verificationSuccess, setVerificationSuccess] = useState(false);

    const [showNormalVerification, setShowNormalVerification] = useState(false)
    const [showDemoVerification, setShowDemoVerification] = useState(false)

    const [showInfoRE, setShowInfoRE] = useState(true);
    const [showInfoDA, setShowInfoDA] = useState(true);

    const [redirect, setRedirect] = useState(false)

    const [firstFetchTermsConditions, setFirstFetchTermsConditions] = useState(true)
    const [showTermsConditions, setShowTermsConditions] = useState(false)
    const [firstFetchPrivacyPolicy, setFirstFetchPrivacyPolicy] = useState(true)
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)

    const {
        sendRequest: requestTermsConditions,
        status: statusTermsConditions,
        data: loadedTermsConditions,
        error: errorTermsConditions,
    } = useHttp(getTermsConditions, true);

    const {
        sendRequest: requestPrivacyPolicy,
        status: statusPrivacyPolicy,
        data: loadedPrivacyPolicy,
        error: errorPrivacyPolicy,
    } = useHttp(getPrivacyPolicy, true);

    const showInfoREHandler = e => {
        e.preventDefault()
        setShowInfoRE(false)
    }

    const showInfoDAHandler = e => {
        e.preventDefault()
        setShowInfoDA(false)
    }

    const toggleTermsConditions = () => {
        setShowTermsConditions(pShowTermsConditions => !pShowTermsConditions)
    }

    const togglePrivacyPolicy = () => {
        setShowPrivacyPolicy(pShowPrivacyPolicy => !pShowPrivacyPolicy)
    }

    useEffect(() => {
        if (verificationSuccess && !redirect) {
            window.location.assign(`${props.uri}/mapi/login?email=${verificationEmail}`)
            setRedirect(true)
        }

        if (!errorTermsConditions) {
            if (
                statusTermsConditions === "completed" &&
                Object.keys(loadedTermsConditions).length > 0 &&
                Object.keys(apiTermsConditions).length === 0
            ) {
                setApiTermsConditions({ ...loadedTermsConditions });
            } else if (firstFetchTermsConditions && firstFetchPrivacyPolicy && Object.keys(apiTermsConditions).length === 0) {
                requestTermsConditions();
                setFirstFetchTermsConditions(false)
            }
        }

        if (!errorPrivacyPolicy) {
            if (
                statusPrivacyPolicy === "completed" &&
                Object.keys(loadedPrivacyPolicy).length > 0 &&
                Object.keys(apiPrivacyPolicy).length === 0
            ) {
                setApiPrivacyPolicy({ ...loadedPrivacyPolicy });
            } else if (firstFetchTermsConditions && firstFetchPrivacyPolicy && Object.keys(apiPrivacyPolicy).length === 0) {
                requestPrivacyPolicy();
                setFirstFetchPrivacyPolicy(false)
            }
        }

    }, [
        verificationSuccess,
        verificationEmail,
        redirect,
        errorTermsConditions,
        statusTermsConditions,
        loadedTermsConditions,
        requestTermsConditions,
        apiTermsConditions,
        setApiTermsConditions,
        firstFetchTermsConditions,
        setFirstFetchTermsConditions,
        errorPrivacyPolicy,
        statusPrivacyPolicy,
        loadedPrivacyPolicy,
        requestPrivacyPolicy,
        apiPrivacyPolicy,
        setApiPrivacyPolicy,
        firstFetchPrivacyPolicy,
        setFirstFetchPrivacyPolicy
    ])

    return (
        <Fragment>
            <section className="hero is-fullheight-with-navbar">
                <div className="hero-body is-justify-content-center">
                    <div className="columns is-mobile is-centered">
                        <div className="column is-full-mobile is-half-tablet is-two-thirds-desktop ">
                            <div className="container">
                                {
                                    !verificationSuccess &&
                                    (
                                        <>
                                            <p className="title is-size-2-touch is-size-1-desktop has-text-weight-bold has-text-centered">Bienvenido</p>
                                            <p className="subtitle is-size-5-touch is-size-4-desktop has-text-centered">
                                                Este servicio te dará acceso a la comparación de <strong>precios</strong>,&nbsp;
                                                <strong>promociones</strong> y <strong>descuentos</strong> de <u>miles de productos</u> actualizados diariamente.
                                            </p>
                                            <ValidateEmail
                                                uri={`${props.uri}/mapi`}
                                                verificationEmail={verificationEmail}
                                                setVerificationEmail={setVerificationEmail}
                                                verificationError={verificationError}
                                                setVerificationError={setVerificationError}
                                                setVerificationSuccess={setVerificationSuccess}
                                                setRegistrationError={setRegistrationError}
                                                showInfoRE={setShowInfoRE}
                                                showInfoDA={setShowInfoDA}
                                                setVerificationPending={setVerificationPending}
                                                windowSize={props.windowSize}
                                                showNormalVerification={showNormalVerification}
                                                setShowNormalVerification={setShowNormalVerification}
                                                showDemoVerification={showDemoVerification}
                                                setShowDemoVerification={setShowDemoVerification}
                                            />
                                        </>
                                    )
                                }
                                {
                                    verificationPending && <Loader />
                                }
                                {
                                    verificationSuccess &&
                                    (
                                        <>
                                            <p className="subtitle has-text-centered is-size-5-touch is-size-4-desktop">
                                                En estos momentos estas siendo redirigido a la pagina de autenticación...
                                            </p>
                                            <Loader />
                                        </>
                                    )
                                }



                                <div>
                                    <div className={`notification is-size-7-touch is-size-6-desktop is-info is-light mb-2 py-2  ${showInfoRE ? '' : "is-hidden"}`} >
                                        <button className="delete" onClick={showInfoREHandler}></button>
                                        ...NO TIENES CUENTAS, crea una ingresando los datos que son necesarios
                                    </div>
                                    {(!registrationSuccess && !verificationSuccess) &&
                                        <Registration
                                            uri={`${props.uri}/mapi`}
                                            registrationError={registrationError}
                                            setRegistrationError={setRegistrationError}
                                            setRegistrationSuccess={setRegistrationSuccess}
                                            setRegistrationEmail={setRegistrationEmail}
                                            setVerificationError={setVerificationError}
                                            showInfoRE={setShowInfoRE}
                                            showInfoDA={setShowInfoDA}
                                            setRegistrationPending={setRegistrationPending}
                                            toggleTermsConditions={toggleTermsConditions}
                                            togglePrivacyPolicy={togglePrivacyPolicy}
                                            windowSize={props.windowSize}
                                        />}
                                    {(!registrationSuccess && registrationPending) && <Loader />}
                                </div>
                                {registrationSuccess && !verificationSuccess &&
                                    <article className={`message is-success ${elementsSize}`}>
                                        <div className="message-body has-text-centered">
                                            Felicitaciones, te has registrado exitosamente, ahora debes activar tu cuenta seguiendo los pasos que te enviamos a <strong>{registrationEmail}</strong>
                                        </div>
                                    </article>
                                }

                                <div>
                                    <div className={`notification is-size-7-touch is-size-6-desktop is-info is-light mb-2 py-2  ${showInfoDA ? '' : "is-hidden"}`} >
                                        <button className="delete" onClick={showInfoDAHandler}></button>
                                        ...O SI PREFIERES, puedes usar una cuenta muy limitada existente
                                    </div>
                                    {(!registrationSuccess && !verificationSuccess && !ctx.isLoggedIn) &&
                                        <DemoAccount
                                            windowSize={props.windowSize}
                                            setShowDemoVerification={setShowDemoVerification}
                                            setShowNormalVerification={setShowNormalVerification}
                                            setRegistrationError={setRegistrationError}
                                            setVerificationError={setVerificationError}
                                        />}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-foot">
                    <div className="container has-text-centered">
                        <div className="is-flex is-justify-content-center is-align-items-center">
                            <p className="is-size-7-touch is-size-6-desktop">
                                <strong>PMATCH</strong>
                                {" by "}
                                <strong>TENAZ - Applied Data Studio</strong>
                            </p>
                            {
                                Object.keys(apiTermsConditions).length > 0 ?
                                    <button
                                        className="button is-text is-size-8-touch is-size-7-desktop"
                                        onClick={toggleTermsConditions}
                                    >
                                        Términos y Condiciones
                                    </button>
                                    :
                                    <button className="button is-text is-size-8-touch is-size-7-desktop" disabled="disabled">
                                        Términos y Condiciones
                                    </button>
                            }
                            {" | "}
                            {
                                Object.keys(apiPrivacyPolicy).length > 0 ?
                                    <button
                                        className="button is-text is-size-8-touch is-size-7-desktop"
                                        onClick={togglePrivacyPolicy}
                                    >
                                        Privacidad
                                    </button>
                                    :
                                    <button className="button is-text is-size-8-touch is-size-7-desktop" disabled="disabled">Privacidad</button>
                            }

                        </div>
                    </div>
                </div>
            </section>
            {
                showTermsConditions &&
                <TermsConditions
                    className={showTermsConditions ? "is-active" : ""}
                    tc={apiTermsConditions}
                    closeModal={toggleTermsConditions}
                    windowSize={props.windowSize}
                />
            }
            {
                showPrivacyPolicy &&
                <PrivacyPolicy
                    className={showPrivacyPolicy ? "is-active" : ""}
                    tc={apiPrivacyPolicy}
                    closeModal={togglePrivacyPolicy}
                    windowSize={props.windowSize}
                />
            }
        </Fragment >
    )
}

export default AuthView;