import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faExclamationTriangle, faCheck, faKeyboard } from "@fortawesome/free-solid-svg-icons";

import useInputDemo from '../../hooks/use-input-demo';

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-_\.]+@([a-zA-Z]|[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9])\.[a-zA-Z0-9]{2,11}(?:\.[a-zA-Z]{2,11})?$/;

const isEmail = (value) => EMAIL_REGEX.test(value);

const ValidateEmailFormDemo = (props) => {
    const { uri } = props

    const {
        value: emailValue,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInputDemo(isEmail);

    let buttonSize = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "is-small" : (props.windowSize === "desktop" || props.windowSize === "wide") ? "is-normal" : "is-small"
    let inputSize = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "is-normal" : (props.windowSize === "desktop" || props.windowSize === "wide") ? "is-medium" : "is-normal"
    let iconSize = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "sm" : (props.windowSize === "desktop" || props.windowSize === "wide") ? "1x" : "sm"
    let formIsValid = false;

    if (emailIsValid) {
        formIsValid = true;
    }

    const onCancelHandler = event => {
        event.preventDefault();
        props.setShowNormalVerification(false);
        props.setShowDemoVerification(false);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        props.setVerificationPending(true)

        if (!formIsValid) {
            return;
        }

        resetEmail();


        let params = { email: emailValue.toLowerCase() };
        let url = new URL(`${uri}/access/check`);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    if (response.status === 403) {
                        throw new Error('403 | Primero debe seguir las instrucciones que le enviamos a su correo electronico.')
                    } else if (response.status === 404) {
                        throw new Error('404 | La cuenta no existe debes registrarte.')
                    } else if (response.status === 401)
                        throw new Error(`${response.status} | Ya existen 2 sesiones abiertas para su usuario, cierralas para poder continuar...`)
                    else {
                        throw new Error(`${response.status} | Ha ocurrido un problema, vuelva a intentarlo.`)
                    }
                }
            })
            .then(data => {
                if (data.email === emailValue.toLowerCase()) {
                    props.setVerificationEmail(emailValue.toLowerCase())
                    props.setVerificationSuccess(true)
                    props.setVerificationPending(false)
                    props.setVerificationError('')
                    props.showInfoRE(false);
                    props.showInfoDA(false);
                }
            })
            .catch(error => {
                props.setVerificationError(error.message)
                props.setVerificationEmail(emailValue.toLowerCase())
                props.setVerificationPending(false)
            })
        props.setShowNormalVerification(false);
        props.setShowDemoVerification(false);
    };

    // const emailClasses = emailHasError ? 'form-control invalid' : 'form-control';

    return (
        <div className="box has-background-grey-lighter py-4 px-3 mb-4">
            <h3 className="title has-text-centered is-size-3-touch is-size-2-desktop has-text-primary">Inicio de Sesión</h3>
            <form onSubmit={submitHandler}>
                <div className="field">
                    <label className="label is-size-6-touch is-size-5-desktop">Correo electrónico</label>
                    <div className="control has-icons-left has-icons-right">
                        <input
                            className={`input ${inputSize} ${!emailIsValid && emailHasError ? 'is-danger' : emailIsValid ? 'is-success' : 'is-primary'}`}
                            type='text'
                            id='vemail'
                            value={emailValue}
                            onChange={emailChangeHandler}
                            onBlur={emailBlurHandler}
                        />
                        <span className="icon is-left">
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                size={iconSize}
                            />
                        </span>
                        <span className="icon is-right">
                            <FontAwesomeIcon
                                icon={!emailIsValid && emailHasError ? faExclamationTriangle : emailIsValid ? faCheck : faKeyboard}
                                size={iconSize}
                            />
                        </span>
                    </div>
                    {emailHasError && <p className="help is-danger is-size-7-touch is-size-6-desktop">Favor ingrese un correo electronico valido.</p>}
                </div>
                <div className='field is-grouped'>
                    <div className='control'>
                        <button className={`button is-primary ${buttonSize}`} disabled={!formIsValid}>Enviar</button>
                    </div>
                    <div className='control'>
                        <button onClick={onCancelHandler} className={`button is-primary is-outlined ${buttonSize}`}>Cerrar</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ValidateEmailFormDemo;