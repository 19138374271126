import { Link } from 'react-router-dom';


const NotAuthenticated = () => {
    return (
        <div className="container">
                <article
                    className="message is-danger "
                >
                    <div className="message-header">
                        <p>NO PERMITIDO</p>
                    </div>
                    <div className="message-body is-size-5">Debe INICIAR SESION antes de acceder a cualquier pagina</div>
                </article>
            <div className="m-2 is-flex is-justify-content-center is-align-items-center">
                <Link
                    className="button is-primary is-medium"
                    to="/"
                >
                    Volver al Inicio
                </Link>
            </div>
        </div>
    );
};

export default NotAuthenticated;