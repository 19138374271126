import { RotatingLines } from "react-loader-spinner"


const Loader = () => {
    
    return (
        <div style={{height: "100%"}} className="is-flex is-align-items-center is-justify-content-center">
            <RotatingLines
                strokeColor="#3f0086"
                strokeWidth="4"
                animationDuration="0.75"
                width="30"
                visible={true}
            />
        </div>
    )
}

export default Loader;