import { Fragment } from "react";

import ValidateEmailForm from "./ValidateEmailForm";
import ValidateEmailFormDemo from "./ValidateEmailFormDemo";

const ValidateEmail = (props) => {

    let buttonSize = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "is-normal" : "is-medium" 


    const onClickHandler = () => {
        props.setShowNormalVerification(pShowForm => !pShowForm)
        props.setVerificationError('')
        props.setRegistrationError('')
        props.setVerificationEmail('')
    }

    const cleanSessionsHandler = () => {
        const params = { email: props.verificationEmail };
        let url = new URL(`${props.uri}/access/reset`);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(`${response.status} | Ha ocurrido un problema, vuelva a intentarlo.`)
                }
            })
            .then(data => {
                if (data.email === props.verificationEmail) {
                    props.setVerificationEmail(data.email)
                    props.setVerificationSuccess(true)
                    props.setVerificationError('')
                    props.showInfoRE(false);
                    props.showInfoDA(false);
                }
            })
            .catch(error => {
                props.setVerificationError(error.message)

            })
    }

    return (
        <Fragment>

            {!props.showNormalVerification && !props.showDemoVerification && (
                <div className="mb-4 has-text-centered">
                    <button onClick={onClickHandler} className={`button is-primary ${buttonSize}`}>Iniciar Sesión</button>
                </div>
            )}

            {props.showNormalVerification && !props.showDemoVerification &&
                (
                    <ValidateEmailForm
                        uri={props.uri}
                        setShowNormalVerification={props.setShowNormalVerification}
                        setShowDemoVerification={props.setShowDemoVerification}
                        verificationEmail={props.verificationEmail}
                        setVerificationEmail={props.setVerificationEmail}
                        setVerificationError={props.setVerificationError}
                        setVerificationSuccess={props.setVerificationSuccess}
                        setVerificationPending={props.setVerificationPending}
                        showInfoRE={props.showInfoRE}
                        showInfoDA={props.showInfoDA}
                        windowSize={props.windowSize}
                    />)}
            {props.showDemoVerification && !props.showNormalVerification &&
                (
                    <ValidateEmailFormDemo
                        uri={props.uri}
                        setShowDemoVerification={props.setShowDemoVerification}
                        setShowNormalVerification={props.setShowNormalVerification}
                        verificationEmail={props.verificationEmail}
                        setVerificationEmail={props.setVerificationEmail}
                        setVerificationError={props.setVerificationError}
                        setVerificationSuccess={props.setVerificationSuccess}
                        setVerificationPending={props.setVerificationPending}
                        showInfoRE={props.showInfoRE}
                        showInfoDA={props.showInfoDA}
                        windowSize={props.windowSize}
                    />)}
            {props.verificationError !== '' && parseInt(props.verificationError.split(' | ')[0]) === 401 && (

                <article
                    className="message is-warning mb-2"
                >
                    <div className="message-body has-text-centered py-2">
                        <p className="subtitle is-size-6-touch is-size-5-desktop">{props.verificationError.split(' | ')[1]}</p>
                        <div>
                            <button onClick={cleanSessionsHandler} className="button is-warning is-size-6-touch is-size-5-desktop">CERRAR TODAS sus sesiones abiertas</button>
                        </div>
                    </div>
                </article>
            )}
            {props.verificationError !== '' && parseInt(props.verificationError.split(' | ')[0]) !== 401 && (
                <article
                    className="message is-warning mb-2"
                >
                    <div className="message-body is-size-6-touch is-size-5-desktop has-text-centered py-2">
                        {props.verificationError.split(' | ')[1]}
                    </div>
                </article>
            )}

        </Fragment>);
};

export default ValidateEmail;
