import { useState, useEffect } from "react";

//👇
//a Util function that will conver the absolute width into breakpoints
const getBreakPoint = (windowWidth) => {
  if (windowWidth) {
    if (windowWidth < 769) {
      return "mobile";
    } else if (windowWidth >= 769 && windowWidth < 1024) {
      return "tablet";
    } else if (windowWidth >= 1024 && windowWidth < 1216) {
      return "desktop";
    } else  if (windowWidth >= 1216){
      return "wide";
    }
  } else {
    return undefined;
  }
}
//☝️

const useWindowSize = () => {
  const isWindowClient = typeof window === "object";

  const [windowSize, setWindowSize] = useState(
    isWindowClient
      ? getBreakPoint(window.innerWidth) 
      : undefined
  );

  useEffect(() => {
    //a handler which will be called on change of the screen resize
    function setSize() {
      setWindowSize(getBreakPoint(window.innerWidth)); 
    }

    if (isWindowClient) {
      //register the window resize listener
      window.addEventListener("resize", setSize);

      //unregister the listerner on destroy of the hook
      return () => {
          console.log("destroying listener")
            window.removeEventListener("resize", setSize)
      };
    }
  }, [isWindowClient, setWindowSize]);

  return windowSize;
}

export default useWindowSize;