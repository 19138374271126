import { Fragment, useState } from "react";


const DemoAccount = (props) => {
    const [showAccount, setShowAccount] = useState(false)

    let elementsSize = (props.windowSize === "mobile" || props.windowSize === "tablet") ? "is-small" : "is-normal"


    const onClickHandler = () => {
        setShowAccount(pShowAccount => !pShowAccount)
    }

    const onUseDemoAccountHandler = () => {
        setShowAccount(pShowAccount => !pShowAccount)
        props.setShowDemoVerification(true)
        props.setShowNormalVerification(false)
        props.setRegistrationError('');
        props.setVerificationError('');
    }

    return (
        <Fragment>
            {!showAccount && (
                <div className="mb-4 has-text-centered">
                    <button onClick={onClickHandler} className={`button is-primary is-outlined ${elementsSize}`}>ver cuenta DEMO</button>
                </div>
            )}
            {showAccount && (
                <div>
                    <article className={`message is-info ${elementsSize}`}>
                        <div className="message-header">
                            <p>Cuenta DEMO</p>
                            <button className={`delete ${elementsSize}`} onClick={onClickHandler}></button>
                        </div>
                        <div className="message-body">
                            <div className="field has-addons mt-1 mr-2 is-justify-content-center">
                                <div className="control">
                                    <span className={`button is-info ${elementsSize}`}>Correo Electronico</span>
                                </div>
                                <div className="control">
                                    <input
                                        className={`input is-info has-text-right has-text-weight-bold ${elementsSize}`}
                                        type='text'
                                        id='demoEmail'
                                        defaultValue="demo@pmatch.cl"
                                        disabled="disabled"
                                    />
                                </div>
                            </div>
                            <div className="field has-addons mt-1 mr-2 is-justify-content-center">
                                <div className="control">
                                    <span className={`button is-info ${elementsSize}`}>Contraseña</span>
                                </div>
                                <div className="control">
                                    <input
                                        className={`input is-info has-text-right has-text-weight-bold ${elementsSize}`}
                                        type='text'
                                        id='demoPassword'
                                        defaultValue="demo2023"
                                        disabled="disabled"
                                    />
                                </div>
                            </div>
                            <div className="field has-text-centered">
                                <div className="control">
                                    <button onClick={onUseDemoAccountHandler} className={`button is-primary ${elementsSize}`}>USAR CUENTA DEMO</button>
                                </div>
                            </div>
                        </div>
                    </article>

                </div>
            )}
        </Fragment>);
};

export default DemoAccount;